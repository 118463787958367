var gmaps = require('google-maps');
gmaps.KEY = 'AIzaSyCncwMtYGRuDyAKFb88POdPNz4FkyxmYbE';

(function() {
    if ($('#maps').length) {

        gmaps.load(function(google) {
            var el = document.getElementById('maps');
        var lat = 51.152131,
            long = 3.267183;
            var center = new google.maps.LatLng(lat, long);
            // var icon = {
            //        url: '/bundles/sitefrontend/img/pin.svg',
            //     scaledSize: new google.maps.Size(35, 35)
            // };

            var options = {
                zoom: 17,
                center: center,
								scrollwheel: false,
								};

            var map = new google.maps.Map(el, options);

            google.maps.event.addDomListener(window, "resize", function() {
                var center = map.getCenter();
                google.maps.event.trigger(map, "resize");
                map.setCenter(center);
            });

            var marker = new google.maps.Marker({
                position: center,
                map: map
            });
        });
    }
})();
