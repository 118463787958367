var slick = require( 'slick-carousel' );
var bgSlider = $( '.js-slider' );

bgSlider.slick( {
  arrows: true,
  nextArrow: '<div class="arrow arrow-right js-right-arrow"></div>',
  prevArrow: '<div class="arrow arrow-left js-left-arrow"></div>',
  fade: true,
  autoplay: true,
  autoplaySpeed: 5000
} );
