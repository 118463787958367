(function ($) {

var UIController = (function(){

  var initialText;

  function deleteError(el){
    var element =  document.getElementById('form__error-message');
    if (typeof(element) != 'undefined' && element !== null) {
      el.removeChild(element);
    }
  }

  function addBtnClass(btn, btnClass){
    btn.removeClass('isLoading').addClass(btnClass);
    setTimeout(function(){
      btn.removeClass(btnClass);
    }, 3000);
  }


  return {

    getData: function(el){
      var formdata = {
        routingTarget:  Routing.generate('site_frontend_ajax_call'),
        method: 'post',
        input: $(el).serializeArray()
      };
      return formdata;
    },


    handleSuccess: function(el, btn){
      deleteError(el);
      $(el)[0].reset();
      addBtnClass(btn, 'loadingSuccess');

      $('.js-form-succes').addClass('form__message--success');
      setTimeout(function(){
        $('.js-form-succes').removeClass('form__message--success');
      }, 3000);


    },

    handleError: function(r, el, btn){
      deleteError(el);
      addBtnClass(btn, 'loadingError');
      el.insertAdjacentHTML('beforeend', '<p class="form__message--error" >'+ r.message +'</p>');
    },

    beforeSend: function(btn){
      btn.addClass('isLoading');
    },


  };

})();

var formController = (function(){

  return {

    sendMail: function(data, callback, beforeSend){
      return $.ajax({
          method: data.method,
          url: data.routingTarget,
          beforeSend: beforeSend,
          data: {
            entity: 'contact',
            form: data.input
          },
          success: callback,
          error: callback
      });

    }



  };

})();


var controller = (function(formController, UIController){

  function handleSubmit(e){

    e.preventDefault();
    var el = e.target;
    var btn = $(el).find('#submit');
    var data = UIController.getData(el);

    formController.sendMail(data, formCallback, UIController.beforeSend(btn));

    function formCallback(r){
      if (r.status){
        UIController.handleSuccess(el, btn);
      } else {
        UIController.handleError(r, el, btn);
      }
    }

  }





  return {
    init: function(){
      var form   = $('#main-contact-form');

      if (form.length) {
          form.on('submit', handleSubmit);
      }

    }
  };

})(formController, UIController);

controller.init();

})(jQuery);
